export default {
  state: {
    profile_dropdown: false,
  },
  getters: {
    _profile_dropdown(state) {
      return state.profile_dropdown;
    },
  },
  mutations: {
    setProfileDropdown(state, payload) {
      state.profile_dropdown = payload;
    },
  },
  actions: {
    ALL_DROPDOWN(context, payload) {
      if (context.getters._profile_dropdown != payload) {
        context.commit("setProfileDropdown", payload);
      }
    },
  },
};
