import axios from "axios";

export default {
  state: {
    site_types: [],
  },
  getters: {
    _site_types(state) {
      return state.site_types;
    },
  },
  mutations: {
    setSiteTypes(state, payload) {
      state.site_types = payload;
    },
  },
  actions: {
    async GET_SITE_TYPES(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/site_types/all", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setSiteTypes", res.data.site_types);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setSiteTypes", []);
            reject(err?.response?.data || err);
          });
      });
    },
    async CREATE_SITE_TYPE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/site_types/create", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    async DELETE_SITE_TYPE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/site_types/delete/${payload}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
