import axios from "axios";

export default {
  state: {
    admin: {},
    auth: false,
    token: null,
  },
  getters: {
    _admin(state) {
      return state.admin;
    },
    _auth(state) {
      return state.auth;
    },
    _token(state) {
      return state.token;
    },
  },
  mutations: {
    setAdmin(state, payload) {
      state.admin = payload;
    },
    setAuth(state, payload) {
      state.auth = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    SET_TOKEN(context, payload) {
      payload
        ? localStorage.setItem("AUTH_TOKEN", payload)
        : localStorage.removeItem("AUTH_TOKEN");
      context.commit("setToken", payload);
    },
    LOGIN_WITH_EMAIL(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/login", payload)
          .then((res) => {
            if (res.data.message != "CODE_REQUIRED") {
              context.commit("setAdmin", res.data.user_information);
              context.commit("setAuth", true);
              context.dispatch("SET_TOKEN", res.data.access_token);
            }
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    CHECK_AUTH(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/auth/check?token=${context.getters._token}`)
          .then((res) => {
            context.commit("setAdmin", res.data.user_information);
            context.commit("setAuth", true);
            context.commit("setToken", res.data.access_token);
            resolve(res.data);
          })
          .catch((err) => {
            localStorage.removeItem("AUTH_TOKEN");
            context.commit("setAdmin", {});
            context.commit("setAuth", false);
            context.commit("setToken", null);
            reject(err?.response?.data || err);
          });
      });
    },
    LOGOUT(context) {
      context.commit("setAdmin", {});
      context.commit("setAuth", false);
      context.commit("setToken", null);
      localStorage.removeItem("AUTH_TOKEN");
    },
  },
};
