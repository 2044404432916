import { createStore } from "vuex";
import auth from "./modules/auth";
import dropdowns from "./modules/dropdowns";
import sites from "./modules/sites";
import companies from "./modules/companies";
import site_types from "./modules/site_types";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    dropdowns,
    sites,
    companies,
    site_types,
  },
});
