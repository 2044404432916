import axios from "axios";

export default {
  state: {
    companies: [],
  },
  getters: {
    _companies(state) {
      return state.companies;
    },
  },
  mutations: {
    setCompanies(state, payload) {
      state.companies = payload;
    },
  },
  actions: {
    async GET_COMPANIES(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/companies/all", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setCompanies", res.data.companies);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setCompanies", []);
            reject(err?.response?.data || err);
          });
      });
    },
    async CREATE_COMPANY(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/companies/create", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    async DELETE_COMPANY(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/companies/delete/${payload}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
