<template>
  <div class="loader">
    <svg
      id="katman_2"
      data-name="katman 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 192.77 49.36">
      <defs>
        <clipPath id="clippath">
          <polygon
            class="cls-3"
            points="22.66 0 0 49.36 11.21 49.36 22.66 24.45 28.26 12.22 22.66 0" />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="-91.02"
          y1="212.76"
          x2="-90.78"
          y2="212.76"
          gradientTransform="translate(-44949.66 19250.94) rotate(90) scale(211.33 -211.33)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#2aa9e0" />
          <stop offset=".51" stop-color="#1e76bc" />
          <stop offset=".96" stop-color="#29285e" />
          <stop offset="1" stop-color="#29285e" />
        </linearGradient>
      </defs>
      <g id="Yatay_Logo" data-name="Yatay Logo">
        <g>
          <polygon
            class="cls-1"
            points="29.96 15.88 24.35 28.13 34.1 49.36 45.32 49.36 29.96 15.88" />
          <g class="cls-2">
            <rect
              class="cls-4"
              x="-13.31"
              y="-2.76"
              width="54.89"
              height="54.89"
              transform="translate(-13.31 17.22) rotate(-45)" />
          </g>
        </g>
        <g>
          <path
            class="cls-1"
            d="M78.21,23.95c-.19,.63-.48,1.19-.87,1.69-.38,.5-.85,.92-1.41,1.27-.03,.03-.06,.05-.1,.06-.04,.01-.08,.03-.1,.06l2.69,5.3h-2.36l-2.36-4.61c-.17,.03-.33,.05-.5,.06-.17,.01-.35,.02-.54,.02h-2.73v4.52h-2.23v-15.32h4.96c.63,0,1.21,.08,1.76,.24,.55,.16,1.05,.38,1.51,.66,1.13,.71,1.88,1.7,2.27,2.98,.01,.07,.03,.13,.04,.19,.01,.06,.03,.12,.06,.19v.1c.03,.07,.04,.15,.05,.23,0,.08,.02,.17,.03,.25v.02c.01,.08,.02,.17,.02,.25v.58c0,.08,0,.17-.02,.25v.04c-.01,.08-.02,.16-.03,.24,0,.08-.02,.16-.05,.24v.08c-.06,.15-.09,.28-.1,.4m-5.27,1.9c.46,0,.88-.1,1.26-.29,.38-.19,.71-.45,.99-.77,.28-.32,.5-.69,.67-1.1,.17-.41,.25-.84,.25-1.29s-.08-.86-.25-1.28c-.17-.42-.39-.78-.67-1.1s-.61-.57-.99-.76c-.38-.19-.8-.29-1.26-.29h-2.98v6.88h2.98Z" />
          <polygon
            class="cls-1"
            points="91.39 22.89 96.18 32.29 93.83 32.29 89.99 24.77 88.09 27.31 88.09 32.29 85.88 32.29 85.88 17.03 88.09 17.03 88.09 23.95 93.18 17.03 95.72 17.03 91.39 22.89" />
          <path
            class="cls-1"
            d="M103.65,32.29l6.23-15.28,6.23,15.28h-2.27l-.46-1.21h-6.98l-.48,1.21h-2.27Zm3.5-3.17h5.46l-2.73-7.01-2.73,7.01Z" />
          <path
            class="cls-1"
            d="M128.91,17.03c1.03,0,1.99,.2,2.89,.6,.9,.4,1.68,.95,2.35,1.65,.67,.7,1.2,1.5,1.58,2.43,.39,.92,.58,1.91,.58,2.97s-.19,2.05-.56,2.97c-.38,.92-.89,1.73-1.55,2.42-.66,.69-1.44,1.23-2.35,1.64-.9,.4-1.88,.6-2.94,.6h-5.34v-15.28h5.34Zm-.25,13.32c.78,0,1.49-.15,2.15-.45,.65-.3,1.22-.71,1.69-1.22,.47-.52,.84-1.12,1.11-1.81,.26-.69,.4-1.42,.4-2.2s-.13-1.51-.4-2.2c-.27-.69-.64-1.29-1.12-1.81-.48-.52-1.05-.92-1.7-1.22-.65-.3-1.36-.45-2.13-.45h-2.86v11.36h2.86Z" />
          <polygon
            class="cls-1"
            points="155.47 17.01 155.47 17.03 150.74 27.33 150.74 32.33 150.74 32.35 148.51 32.35 148.51 32.33 148.51 27.33 143.78 17.03 143.8 17.03 143.78 17.01 146.26 17.01 149.64 25.18 153.01 17.01 155.47 17.01" />
          <path
            class="cls-1"
            d="M162.94,32.29l6.23-15.28,6.23,15.28h-2.27l-.46-1.21h-6.98l-.48,1.21h-2.27Zm3.5-3.17h5.46l-2.73-7.01-2.73,7.01Z" />
          <path
            class="cls-1"
            d="M191.25,24.7c.1,.07,.19,.15,.27,.24,.08,.09,.16,.18,.23,.26,.32,.4,.57,.84,.75,1.32,.18,.48,.27,.98,.27,1.51,0,.12,0,.22-.02,.29-.04,.78-.28,1.48-.71,2.11-.39,.58-.89,1.05-1.5,1.39-.61,.34-1.29,.51-2.04,.51h-1.38c-.75,0-1.43-.17-2.04-.51-.61-.34-1.11-.8-1.5-1.39-.11-.17-.22-.36-.33-.58l1.94-1.5c.04,.26,.13,.51,.26,.74,.13,.23,.3,.43,.49,.6s.42,.31,.67,.41c.25,.1,.51,.15,.79,.15h.83c.57,0,1.06-.18,1.48-.55,.42-.37,.67-.82,.75-1.37,0-.05,0-.11,.01-.17,0-.05,.01-.11,.01-.17,0-.31-.06-.59-.17-.85-.11-.26-.26-.5-.46-.7-.19-.2-.42-.37-.69-.49-.26-.13-.54-.19-.83-.21h-1.27c-.52-.01-1-.11-1.45-.3-.45-.19-.86-.44-1.22-.76-.2-.14-.36-.31-.5-.5-.32-.4-.57-.84-.75-1.32-.18-.48-.27-.98-.27-1.51v-.29c.07-.79,.31-1.49,.73-2.11,.39-.58,.89-1.05,1.5-1.39,.61-.34,1.29-.51,2.04-.51h1.36c.75,0,1.44,.17,2.05,.51,.62,.34,1.12,.8,1.51,1.39,.04,.07,.09,.15,.13,.23,.05,.08,.09,.16,.14,.23l-1.9,1.48c-.12-.5-.39-.92-.79-1.25-.4-.33-.87-.5-1.4-.5h-.83c-.28,0-.55,.05-.8,.15-.26,.1-.48,.23-.68,.41-.19,.17-.36,.38-.49,.61-.13,.24-.22,.49-.26,.75-.01,.06-.02,.11-.02,.17v.17c0,.31,.06,.59,.17,.85,.11,.26,.26,.5,.46,.7,.19,.2,.42,.37,.69,.49,.26,.13,.54,.2,.83,.21h1.27c.51,.01,1,.11,1.45,.3,.45,.19,.86,.44,1.22,.76" />
          <path
            class="cls-1"
            d="M47.77,32.29l6.23-15.28,6.23,15.28h-2.27l-.46-1.21h-6.98l-.48,1.21h-2.27Zm3.5-3.17h5.46l-2.73-7.01-2.73,7.01Z" />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "LoaderComp",
};
</script>
<style scoped lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f4faff;

  svg {
    width: 150px;
    user-select: none;
    animation: zoom 0.5s infinite;
    scale: 1.5;
  }
}

@keyframes zoom {
  0% {
    scale: 1.5;
  }
  50% {
    scale: 1.6;
  }
  100% {
    scale: 1.5;
  }
}
</style>
<style>
.cls-1 {
  fill: #414142;
}

.cls-2 {
  clip-path: url(#clippath);
}

.cls-3 {
  fill: none;
}

.cls-4 {
  fill: url(#linear-gradient);
}
</style>
