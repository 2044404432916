import axios from "axios";

export default {
  state: {
    sites: [],
    deleted_sites: [],
    site: {},
    iis_ids: [],
    count: 0,
    categories: [],
  },
  getters: {
    _sites(state) {
      return state.sites;
    },
    _deleted_sites(state) {
      return state.deleted_sites;
    },
    _site(state) {
      return state.site;
    },
    _iis_ids(state) {
      return state.iis_ids;
    },
    _count(state) {
      return state.count;
    },
    _categories(state) {
      return state.categories;
    },
  },
  mutations: {
    setSites(state, payload) {
      state.sites = payload;
    },
    setDeletedSites(state, payload) {
      state.deleted_sites = payload;
    },
    setSite(state, payload) {
      state.site = payload;
    },
    setIisIds(state, payload) {
      state.iis_ids = payload;
    },
    setCount(state, payload) {
      state.count = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
  },
  actions: {
    async GET_SITE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sites/get/" + payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setSite", res.data.site);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setSite", {});
            reject(err?.response?.data || err);
          });
      });
    },

    async GET_IIS_IDS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sites/get-iis-ids", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setIisIds", res.data.array);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setIisIds", []);
            reject(err?.response?.data || err);
          });
      });
    },

    async GET_SITES(context, query = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sites/all", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
            params: query,
          })
          .then((res) => {
            context.commit("setSites", res.data.sites);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setSites", []);
            reject(err?.response?.data || err);
          });
      });
    },

    async GET_DELETED_SITES(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sites/deleted-all", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setDeletedSites", res.data.sites);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setDeletedSites", []);
            reject(err?.response?.data || err);
          });
      });
    },

    async CREATE_SITE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/sites/create", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    async UPDATE_SITE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put("/sites/update", payload, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    async DELETE_SITE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/sites/delete/${payload}`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },

    async DOCUMENT_COUNT(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/sites/document-count", {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setCount", res.data.count);
            context.commit("setCategories", res.data.categories);
            resolve(res?.data);
          })
          .catch((err) => {
            context.commit("setCount", 0);
            context.commit("setCategories", []);
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
