<template>
  <transition mode="out-in" name="blur">
    <div class="sidebar" v-if="menu">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="" />

        <button
          class="arcadias-btn-outline-light ms-2"
          @click="hamburgerMenu()">
          <i class="bi bi-chevron-left"></i>
        </button>
      </div>

      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'Home' }">
            <i class="bi bi-view-list"></i>
            Listeyi Gör
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddSite' }">
            <i class="bi bi-database-add"></i>
            Site Ekle
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddCompany' }">
            <i class="bi bi-building-add"></i>
            Firma Ekle
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddSiteType' }">
            <i class="bi bi-node-plus"></i>
            Site Tipi Ekle
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'DeletedSites' }">
            <i class="bi bi-trash"></i>
            Silinen Siteler
          </router-link>
        </li>
      </ul>

      <div
        class="arc-logo w-100 d-flex flex-column align-items-center justify-content-end">
        <p
          style="color: #2491cf; margin-bottom: 0; font-size: 0.8rem"
          v-for="category of _categories">
          {{ category._id }}: {{ category.count }}
        </p>
        <p style="color: #2491cf">Mevcut Site: {{ _count }}</p>
        <img src="@/assets/arkadyas.png" alt="" />
      </div>
    </div>
    <div class="sidebar small" v-else>
      <div class="logo">
        <button class="arcadias-btn-outline-light" @click="hamburgerMenu()">
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>

      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'Home' }">
            <i class="bi bi-view-list"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddSite' }">
            <i class="bi bi-database-add"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddCompany' }">
            <i class="bi bi-building-add"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'AddSiteType' }">
            <i class="bi bi-node-plus"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link d-flex align-items-center gap-2"
            :to="{ name: 'DeletedSites' }">
            <i class="bi bi-trash"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarComp",
  data() {
    return {
      menu: true,
    };
  },
  computed: {
    ...mapGetters(["_count", "_categories"]),
  },
  mounted() {
    this.$store.dispatch("DOCUMENT_COUNT").catch(() => {
      this.$swal({
        icon: "error",
        text: "Site Sayısı Getirilirken Bir Sorun Oluştu.",
      });
    });
  },
  methods: {
    hamburgerMenu() {
      this.menu = !this.menu;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  height: 100vh;
  width: 250px;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;

  &.small {
    justify-content: unset !important;
    width: 75px;
    min-width: 75px;
    background-color: #f4faff;

    .logo {
      padding: 1rem !important;
    }
  }

  .arc-logo {
    flex: 1;
    margin-bottom: 25px;
  }

  .logo {
    font-weight: 500;
    font-size: 1rem;
    color: #205679;
    font-family: "Poppins";
    padding: 1.5rem;
    border-bottom: 1px dashed #dddddd;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 150px;
    }
  }

  ul {
    list-style: none;
    margin-top: 1rem;

    a {
      border-radius: 10px;
      margin: 0 15px;
      margin-bottom: 10px;
      transition: 0.3s all ease-in-out;
      font-weight: 500;
      color: #205679;

      &:hover {
        background-color: #2055791f;
      }

      &.router-link-active {
        background-color: #2491cf;
        color: white;

        i {
          color: white;
        }
      }
    }

    li.head {
      margin-top: 18px;
      color: #205679;
      font-weight: 500;
      margin: 0 15px;
      margin-bottom: 10px;
    }
  }
}
</style>
