<template>
  <div class="header">
    <div class="avatar">
      <img
        dropdown-action="not_close"
        @click="showDropdown()"
        :src="`https://secure.gravatar.com/avatar/${md5(_admin.email)}`"
        alt="" />
      <transition name="blur" mode="in-out">
        <div v-if="_profile_dropdown" class="dropdown">
          <div class="top">
            <img
              :src="`https://secure.gravatar.com/avatar/${md5(_admin.email)}`"
              alt="" />
            <strong>{{ _admin.email }}</strong>
            <small>Yönetici</small>
          </div>
          <ul>
            <li class="arcadias-btn-danger">
              <a class="dropdown-item" href="#" @click.prevent="logout()">
                <i class="bi bi-box-arrow-right"></i>
                Güvenli Çıkış
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <div class="personel_information">
      <span>{{ _admin.full_name }}</span>
      <span>Yönetici</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import md5 from "crypto-js/md5";

export default {
  name: "HeaderComp",
  data: () => ({}),
  computed: {
    ...mapGetters(["_admin", "_profile_dropdown"]),
  },
  methods: {
    showDropdown() {
      this.$store.commit("setProfileDropdown", !this._profile_dropdown);
    },
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "Login" });
      this.$swal({
        icon: "success",
        text: "Oturumunuz Sonlandırılmıştır, İyi günler.",
      });
    },
    md5,
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 75px;
  width: 100%;
  border-bottom: 1px dashed #dddddd;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;

  .avatar {
    padding: 20px;
    position: relative;
    border-radius: 50%;
    img {
      width: 35px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s all ease-in-out;

      &:hover {
        box-shadow: 0px 0px 0px 2px rgba(209, 209, 209, 1);
      }

      &:active {
        box-shadow: 0px 0px 0px 2px rgb(165, 165, 165);
      }
    }

    .dropdown {
      position: absolute;
      right: 15px;
      border-radius: 15px;
      border: 1px solid #dddddd;
      background-color: white;
      padding: 5px;
      margin-top: 15px;

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0 15px;
        pointer-events: all;

        li {
          border-radius: 10px;
          transition: 0.3s all ease-in-out;
          font-weight: 700;
          padding: 5px 15px;
          margin-bottom: 10px;

          i {
            margin-right: 8px;
          }
        }
      }

      div.top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 250px;
        margin: 30px 0;

        img {
          width: 50px;
        }
      }
    }
  }

  .personel_information {
    display: flex;
    flex-direction: column;
    text-align: end;
    justify-content: center;

    span {
      font-weight: 600;
      color: #000000;
    }

    span:nth-child(2) {
      color: #2491cf;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .buttons {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    gap: 10px;

    button {
      border-radius: 12px;
      background-color: #ffffff;
      outline: none;
      border: none;
      width: 45px;
      height: 45px;
      transition: 0.3s all ease-in-out;
      border: 1px solid #ffffff;

      i {
        color: #205679;
      }

      &:hover {
        background-color: #dddddd;
      }

      &:active {
        border-color: #c2c2c2;
      }
    }
  }
}
</style>
