import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      auth: true,
    },
  },
  {
    path: "/deleted-sites",
    name: "DeletedSites",
    component: () => import("@/views/DeletedSites"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/add-company",
    name: "AddCompany",
    component: () => import("@/views/AddCompany"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/add-site-type",
    name: "AddSiteType",
    component: () => import("@/views/AddSiteType"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/add-site",
    name: "AddSite",
    component: () => import("@/views/AddSite"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/update-site/:id",
    name: "UpdateSite",
    component: () => import("@/views/UpdateSite"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    meta: {
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
